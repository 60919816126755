// Colors
$greenSeaweed: rgba(2, 128, 144, 1);
$blueQueen: rgba(69, 105, 144, 1);
$redFire: #5CB2F9;

// Fonts
$fontAsap: 'Asap', sans-serif;

.login {
  overflow: hidden;
  background-color: rgb(37, 36, 36);
  padding: 40px 30px 30px 30px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  transform: (translate(-50%, -50%));
  transition: (transform 300ms, box-shadow 300ms);
  box-shadow: 5px 10px 10px rgba($greenSeaweed, 0.2);
  
  &::before, &::after {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    border-top-left-radius: 40%;
    border-top-right-radius: 45%;
    border-bottom-left-radius: 35%;
    border-bottom-right-radius: 40%;
    z-index: -1;
  }
  
  &::before {
    left: 40%;
    bottom: -130%;
    background-color: rgba($blueQueen, 0.15);
    animation: wawes 6s infinite linear;
  }
  
  &::after {
    left: 35%;
    bottom: -125%;
    background-color: rgba($greenSeaweed, 0.2);
    animation: wawes 7s infinite;
  }
  
  > input {
    font-family: $fontAsap;
    display: block;
    border-radius: 5px;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.705);
    width: 100%;
    border: 0;
    padding: 10px 10px;
    margin: 15px -10px;
  }
  
  > button {
    font-family: $fontAsap;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    width: auto;
    border: 0;
    padding: 10px 10px;
    margin-top: 10px;
    margin-left: -5px;
    border-radius: 5px;
    background-color: $redFire;
    transition: (background-color 300ms);
    
    &:hover {
      background-color: darken($redFire, 5%);
    }
  }
}

  @keyframes wawes {
  from { transform: (rotate(0)); }
  to { transform: (rotate(360deg)); }
}
